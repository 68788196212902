export const LOAD_FACILITIES_SUCCESS = "LOAD_FACILITIES_SUCCESS";
export const LOAD_FACILITIES_ERROR = "LOAD_FACILITIES_ERROR";
export const BEGIN_FACILITIES_API_CALL = "BEGIN_FACILITIES_API_CALL";
export const LOAD_MONITORING_PLANS_SUCCESS = "LOAD_MONITORING_PLANS_SUCCESS";
export const LOAD_MONITORING_PLANS_ARRAY_SUCCESS =
  "LOAD_MONITORING_PLANS_ARRAY_SUCCESS";
export const BEGIN_MONITORING_PLANS_API_CALL =
  "BEGIN_MONITORING_PLANS_API_CALL";
export const LOAD_MONITORING_METHODS_SUCCESS =
  "LOAD_MONITORING_METHODS_SUCCESS";
export const BEGIN_MONITORING_METHODS_API_CALL =
  "BEGIN_MONITORING_METHODS_API_CALL";
export const LOAD_MONITORING_MATSMETHODS_SUCCESS =
  "LOAD_MONITORING_MATSMETHODS_SUCCESS";
export const BEGIN_MONITORING_MATSMETHODS_API_CALL =
  "BEGIN_MONITORING_MATSMETHODS_API_CALL";
export const ADD_FACILITY_TAB = "ADD_FACILITY_TAB";
export const REMOVE_FACILITY_TAB = "REMOVE_FACILITY_TAB";
export const LOAD_MONITORING_SYSTEMS_SUCCESS =
  "LOAD_MONITORING_SYSTEMS_SUCCESS";
export const LOAD_MONITORING_SYSTEMS_FUEL_FLOWS_SUCCESS =
  "LOAD_MONITORING_SYSTEMS_FUEL_FLOWS_SUCCESS";
export const BEGIN_MONITORING_SYSTEMS_API_CALL =
  "BEGIN_MONITORING_SYSTEMS_API_CALL";
export const LOAD_MONITORING_SYSTEMS_COMPONENTS_SUCCESS =
  "LOAD_MONITORING_SYSTEMS_COMPONENTS_SUCCESS";
export const BEGIN_MONITORING_SYSTEMS_COMPONENTS_API_CALL =
  "BEGIN_MONITORING_SYSTEMS_COMPONENTS_API_CALL";
export const SET_SECTION_SELECTION_STATE = "SET_SECTION_SELECTION_STATE";
export const SET_LOCATION_SELECTION_STATE = "SET_LOCATION_SELECTION_STATE";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_LOCATIONS_STATE = "SET_LOCATIONS_STATE";
export const EMPTY_MONITORING_PLANS = "EMPTY_MONITORING_PLANS";
export const SET_CHECKOUT_STATE = "SET_CHECKOUT_STATE";
export const SET_INACTIVE_STATE = "SET_INACTIVE_STATE";
export const LOAD_DROPDOWNS_SUCCESS = "LOAD_DROPDOWN_SUCCESS";
export const UPDATE_DROPDOWN_SUCCESS = "UPDATE_DROPDOWN_SUCCESS";
export const BEGIN_MDM_API_CALL = "BEGIN_MDM_API_CALL";
export const UPDATE_DROPDOWNS = "UPDATE_DROPDOWNS";
export const SET_EXPORT_STATE = "SET_EXPORT_STATE";
export const IS_VIEW_DATA_LOADED = "IS_VIEW_DATA_LOADED";
export const SET_VIEW_DATA = "SET_VIEW_DATA";
export const SET_VIEW_DATA_COLUMNS = "SET_VIEW_DATA_COLUMNS";
export const SET_REPORTING_PERIODS = "SET_REPORTING_PERIODS";
export const SET_VIEW_TEMPLATE_SELECTION = "SET_VIEW_TEMPLATE_SELECTION";
export const SET_CHECKED_OUT_LOCATIONS = "SET_CHECKED_OUT_LOCATIONS";
export const SET_CURRENT_TAB_INDEX = "SET_CURRENT_TAB_INDEX";
export const SET_WORKSPACE = "SET_SET_WORKSPACE";