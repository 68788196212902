const initialState = {
  facilities: [],
  monitoringPlans: [],
  apiCallsInProgress: {
    facilities: false,
    monitoringPlans: false,
    monitoringMethods: false,
    monitoringSystems: false,
    monitoringMatsMethods: false,
    monitoringSystemsComponents: false,
  },
  openedFacilityTabs: {
    monitoringPlans: [],
    qaCertTestSummary: [],
    qaCertEvent: [],
    export: [],
    emissions: [],
  },
  checkedOutLocations: [],
  dropdowns: {
    defaults: [],
    formulas: [],
    loads: [],
    locationAttributesAndRelationships: [],
    methods: [],
    qualifications: [],
    lmeQualifications: [],
    leeQualifications: [],
    pctQualifications: [],
    rectangularDuctWafs: [],
    spans: [],
    systems: [],
    unitFuels: [],
    unitControls: [],
    unitCapacities: [],
    fuelFlows: [],
    systemComponents: [],
    analyzerRanges: [],
    matsMethods: [],
  },
  currentSection: "",
  workspaceState: "monitoringPlans",
  currentTabIndex: 0,
};
export default initialState;